import React from 'react';
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import { Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { FaFacebook, FaTwitter, FaInstagram, FaComments } from "react-icons/fa";



function MainNavbar() {
  const { t, i18n } = useTranslation();

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  return (
    <Navbar
      collapseOnSelect
      expand="lg"
      className="bg-body-tertiary"
      style={{ position: "fixed", zIndex: 9999, width: "100%", paddingLeft: 20 }}
    >
      <Navbar.Brand as={Link} to="/">
        <img
          src="/assets/images/TBAFinal.png"
          alt="tba logo"
          style={{ width: "40%" }}
        />
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="responsive-navbar-nav" />
      <Navbar.Collapse id="responsive-navbar-nav">
        <Nav className="me-auto">
          <NavDropdown title={t("welcome_message")} id="collapsible-nav-dropdown">
            <NavDropdown.Item href="/about/trustbroker-africa">
              {t("about_us")}
            </NavDropdown.Item>
            <NavDropdown.Item href="/about/steering-committee">
              {t("steering")}
            </NavDropdown.Item>
            <NavDropdown.Item href="/about/resources">
              {t("resources")}
            </NavDropdown.Item>
          </NavDropdown>
          <a
            className="nav-link"
            href="/registry/index.html" target="_blank" rel="noopener noreferrer">
 

            {t("registry")}
          </a>
          <Nav.Link href="/join">{t("join")}</Nav.Link>
          <NavDropdown title={t("community")} id="collapsible-nav-dropdown">
            <NavDropdown.Item href="/community/media">{t("media")}</NavDropdown.Item>
          </NavDropdown>
          <Nav.Link href="/support">{t("support")}</Nav.Link>
        </Nav>
      </Navbar.Collapse>
      <div>
        <button onClick={() => changeLanguage("en")}>English</button>
        <button onClick={() => changeLanguage("fr")}>Français</button>
      </div>
    </Navbar>
  );
}
export default MainNavbar